(function () {
	'use strict';
	angular
		.module('measuring')
		.controller('MeasureCtrl', [
			'$scope',
			'$rootScope',
			'seedcodeCalendar',
			'dataStore',
			'manageMeasure',
			'manageConfig',
			'utilities',
			'environment',
			MeasureCtrl,
		]);

	function MeasureCtrl(
		$scope,
		$rootScope,
		seedcodeCalendar,
		dataStore,
		manageMeasure,
		manageConfig,
		utilities,
		environment
	) {
		var highestValue = 0;
		var HeightPadMultiplier = 1.2;
		var currentView;
		var currentMeasureAggregate;
		var previousHorizonGrouping;
		//Load config from our model and watch for future updates
		$scope.calendar = {};
		$scope.calendar.config = seedcodeCalendar.get('config');
		$scope.calendar.isPhone = environment.isPhone;

		$scope.measure = {};

		//Set default measure setting tab
		$scope.measure.settingsTab = 'data';

		$scope.updateMeasureItem = updateMeasureItem;

		$scope.availableMeasureFields = availableMeasureFields();

		//Remove analytics data
		$scope.$on('$destroy', () => {
			seedcodeCalendar.init('analytics', null);
		});

		//Hide the chart if we get a broadcast to do so
		$scope.$on('calendarInfoClose', function (event, data) {
			close();
		});

		$scope.$on('loading-events', function (event, data) {
			if (data === true) {
				//Clear chart data so we get a cleaner render when showing the chart again
				$scope.chartData.chartData.series = [];
				$scope.chartTrigger = new Date().getTime(); //Trigger the chart to redraw

				allowMeasureAggregate();
				allowCombineResources();
			}
		});

		$scope.$on('eventsRendered', function (event, data) {
			var horizonDefinitions;
			var config = seedcodeCalendar.get('config');
			var view = seedcodeCalendar.get('view');

			var update = data && data.refresh ? false : true;

			// Reset the chart scale when switching horizon column definitions
			if (view.name === 'basicHorizon') {
				horizonDefinitions = $.fullCalendar.getClusterDefinitions();
				if (horizonDefinitions.grouping !== previousHorizonGrouping) {
					update = false;
				}
				previousHorizonGrouping = horizonDefinitions.grouping;
			}

			createChart(update);

			//Update available fields for measuring
			$scope.availableMeasureFields = availableMeasureFields();
		});

		// Show settins buy default of that is selected
		if ($scope.calendar.config.showMeasureSettings) {
			showSettings(true);
		} else {
			showSettings(false);
		}

		createChart();

		$scope.help = function (page, pagesf, fullURL, pageShare) {
			utilities.help(page, pagesf, fullURL, pageShare);
		};

		function allowMeasureAggregate() {
			var view = seedcodeCalendar.get('view');
			var config = seedcodeCalendar.get('config');
			var clusterOptions = $.fullCalendar.getClusterDefinitions();
			$scope.allowMeasureAggregate =
				view &&
				view.name.indexOf('Horizon') > -1 &&
				clusterOptions.type === 'day';
		}

		function allowCombineResources() {
			var view = seedcodeCalendar.get('view');
			var config = seedcodeCalendar.get('config');
			if (view && view.name === 'basicResourceHor') {
				$scope.allowCombineResources = true;
			} else {
				$scope.allowCombineResources = false;
			}
		}

		function createChart(update) {
			var view = seedcodeCalendar.get('view');
			var config = seedcodeCalendar.get('config');
			var measureResult;

			allowMeasureAggregate();
			allowCombineResources();

			// Force full refresh on view change
			if (view.name !== currentView) {
				update = false;
				currentView = view.name;
			}

			if (config.measureAggregate !== currentMeasureAggregate) {
				update = false;
				currentMeasureAggregate = config.measureAggregate;
			}

			if (!update) {
				highestValue = 0;
			}

			var numberFormatObject = {
				decimalCharacter: config.measureDecimalSymbol,
				decimalPlaces: config.measureDecimalPlaces,
				thousandsSeparator: config.measureThousandsSeparator,
				numberLabelBefore: config.measureNumberLabelBefore,
				numberLabelAfter: config.measureNumberLabelAfter,
			};

			//Calculate the event measure routine, adjust end date to it is inclusive rather than exclusive
			manageMeasure.createEventDayCount(
				view.start,
				view.startOverride,
				moment(view.end).subtract(1, 'day'),
				function (result) {
					measureResult = result;
					processChartData(result);
				}
			);

			function processChartData(result) {
				var startColumn = result.startColumn;
				var endColumn = result.endColumn;
				var startPosition = result.startPosition;
				var contentWidth = result.contentWidth;

				var thresholdFormatted = config.showMeasureThreshold
					? utilities.formatDisplayNumber(
							Number(config.measureThreshold),
							config.measureNumberFormat,
							config.measureType,
							numberFormatObject
					  )
					: null;

				result.threshold = config.measureThreshold;
				result.showThreshold = config.showMeasureThreshold;
				result.thresholdDisplay = thresholdFormatted;
				result.thresholdTotalDisplay = utilities.formatDisplayNumber(
					Math.abs(result.thresholdTotal),
					config.measureNumberFormat,
					config.measureType,
					numberFormatObject
				);

				//Format total count number
				result.totalCountFormatted = utilities.formatDisplayNumber(
					result.totalCount,
					config.measureNumberFormat,
					config.measureType,
					numberFormatObject
				);

				$scope.measure.data = result;
				var data = createChartData(measureResult, update);
				$scope.chartData = data;
				$scope.measure.breakout = data.breakoutData;

				//Expose analytics summary
				var breakoutSummary = {};
				breakoutSummary.totalCount = result.totalCount;
				breakoutSummary.breakout = result.breakout;
				breakoutSummary.breakoutType = result.breakoutType;

				if (result.breakout && result.breakoutType == 'custom') {
					let view = seedcodeCalendar.get('view');
					let labelObj = view.calendar.options.breakout;
					breakoutSummary.breakoutLabel = labelObj.label;
				} else if (result.breakout && result.breakoutType) {
					breakoutSummary.breakoutLabel =
						result.breakoutType.charAt(0).toUpperCase() +
						result.breakoutType.slice(1);
				}

				exposeAnalytics('breakoutSummary', breakoutSummary);

				var highValue =
					config.showMeasureThreshold &&
					config.measureThreshold > highestValue
						? config.measureThreshold
						: highestValue;
				highValue = Math.max(highValue, 1) * HeightPadMultiplier;
				var colWidth = view.getColWidth();
				var colCount = view.getColCnt();
				// var startPosition = view.colLeft(startColumn);
				// var widthOffset = result.includeLastColumn ? colWidth : 0;
				// var contentWidth = (view.colLeft(endColumn) - 0) + colWidth;//contentWidth = (view.colLeft(colCount - 1) - startPosition) + (colWidth * 3);//view.calendar.getContentWidth();
				// var contentWidth = (view.colLeft(endColumn) - startPosition) + widthOffset;//contentWidth = (view.colLeft(colCount - 1) - startPosition) + (colWidth * 3);//view.calendar.getContentWidth();
				//var lastColWidth = view.colRight(colCount- 1) - view.colLeft(colCount - 1);
				// var contentWidth = (view.calendar.getContentWidth() - lastColWidth) + colWidth;
				var ticks = createTicks(highValue);
				var options = {
					chart: {
						useBreakoutColors: true, //measureResult.breakoutType === 'status',
						highValue: highValue,
						colWidth: colWidth,
						colCount: colCount,
						startPosition: startPosition,
						contentWidth: contentWidth,
						left: startPosition + colWidth / 2 + 'px',
						threshold: config.showMeasureThreshold
							? config.measureThreshold
							: null,
						thresholdFormatted: thresholdFormatted,
						thresholdLineColor: config.measureThresholdLineColor,
						thresholdFillColor: config.measureThresholdFillColor,
						thresholdFillOpacity:
							config.measureThresholdFillOpacity,
						thresholdTotalDisplay: result.thresholdTotalDisplay,
						visibleChartPointCount: result.visibleChartPointCount,
						rangeType: result.rangeType,
						partialRange: result.partialRange,
					},
					width: contentWidth + 'px',
					height: '242px',
					showPoint: true,
					showLine: true,
					showArea: true,
					// fullWidth: true,
					showLabel: false,
					axisX: {
						// type: Chartist.AutoScaleAxis,
						showGrid: false,
						showLabel: false,
						offset: 0,
					},
					axisY: {
						// type: Chartist.FixedScaleAxis,
						showGrid: false,
						showLabel: false,
						offset: 0,
						// offset: colWidth / 2,
						low: 0,
						high: highValue,
						// ticks: ticks,
					},
					chartPadding: 0,

					// AutoScaleAxis: false,
					// scaleMinSpace: 2,
				};

				$scope.chartOptions = options;
				$scope.chartTrigger = new Date().getTime();
			}
		}

		function createChartData(measureResult, update) {
			var config = seedcodeCalendar.get('config');
			var gridObject = measureResult.dayGrid;

			var result = {};
			var breakoutItems = [];
			var breakout;

			var exposedItems = [];
			var exposedItem;
			var parentCount = 0;
			var data = {
				labels: [],
				series: [],
			};
			var seriesData;
			var gridData;
			var gridDataOrder;
			var seriesValues;

			var numberFormatObject = {
				decimalCharacter: config.measureDecimalSymbol,
				decimalPlaces: config.measureDecimalPlaces,
				thousandsSeparator: config.measureThousandsSeparator,
				numberLabelBefore: config.measureNumberLabelBefore,
				numberLabelAfter: config.measureNumberLabelAfter,
			};

			for (var parent in gridObject) {
				data.series.push([]);
				breakout = {};
				breakout.name = gridObject[parent].name;
				breakout.display = gridObject[parent].display;
				breakout.shortName = gridObject[parent].shortName;
				breakout.color = gridObject[parent].color;
				breakout.offset = gridObject[parent].offset;
				breakout.sort = gridObject[parent].sort;
				breakout.textColor = gridObject[parent].textColor;
				breakout.totalCount = gridObject[parent].totalCount;
				breakout.totalCountFormatted = utilities.formatDisplayNumber(
					gridObject[parent].totalCount,
					config.measureNumberFormat,
					config.measureType,
					numberFormatObject
				);
				breakout.thresholdRemaining =
					gridObject[parent].thresholdRemaining;
				breakout.overThresholdFormatted = utilities.formatDisplayNumber(
					Math.abs(gridObject[parent].thresholdRemaining),
					config.measureNumberFormat,
					config.measureType,
					numberFormatObject
				);

				breakoutItems.push(breakout);

				exposedItem = {
					name: breakout.name,
					display: breakout.display,
					shortName: breakout.shortName,
					color: breakout.color,
					textColor: breakout.textColor,
					totalCount: breakout.totalCount,
				};

				exposedItems.push(exposedItem);

				gridData = gridObject[parent].gridData;
				gridDataOrder = gridObject[parent].gridDataOrder;

				seriesValues = [];
				for (var i = 0; i < gridDataOrder.length; i++) {
					var gridDataItem = gridData[gridDataOrder[i]];
					if (!parentCount) {
						data.labels.push(gridDataItem.dateString);
					}
					seriesData = {};
					seriesData.value = gridDataItem.count;
					seriesData.valueFormatted = utilities.formatDisplayNumber(
						gridDataItem.count,
						config.measureNumberFormat,
						config.measureType,
						numberFormatObject
					);
					if (
						config.showMeasureThreshold &&
						config.measureThreshold !== '' &&
						config.measureThreshold < gridDataItem.count
					) {
						seriesData.overThresholdFormatted =
							utilities.formatDisplayNumber(
								utilities.floatMath(
									gridDataItem.count,
									config.measureThreshold * -1
								),
								config.measureNumberFormat,
								config.measureType,
								numberFormatObject
							);
					}
					seriesData.label =
						parent !== 'data' ? breakout.display : null;
					seriesData.className = '"' + parentCount + '"';
					seriesData.color = breakout.color;
					seriesData.offset = breakout.offset;
					seriesData.isEstimate = gridDataItem.isEstimate;
					seriesData.isPadding = gridDataItem.isPadding;
					seriesData.dateString = gridDataItem.dateString;
					data.series[parentCount].push(seriesData);

					// if (!update && gridDataItem.count > highestValue) {
					// 	highestValue = gridDataItem.count;
					// }

					if (
						(!update && gridDataItem.count > highestValue) ||
						(update &&
							gridDataItem.count * HeightPadMultiplier >
								highestValue)
					) {
						update = false; //Since our highest point threshold has been exceeded we should no longer treat this as an update
						highestValue = gridDataItem.count;
					}
					seriesValues.push(seriesData.value);
				}

				//Modify the first and last values (should be zero) to make a smoother line extending off the chart
				var seriesValueLength = seriesValues.length;

				//First
				var paddingCount = measureResult.paddingColumnCount / 2;
				for (var i = paddingCount - 1; i >= 0; i--) {
					data.series[parentCount][i].value =
						data.series[parentCount][i + 1].value -
						(data.series[parentCount][i + 2].value -
							data.series[parentCount][i + 1].value) *
							0.3;
				}

				//Last
				for (var i = paddingCount; i > 0; i--) {
					data.series[parentCount][seriesValueLength - i].value =
						data.series[parentCount][seriesValueLength - (i + 1)]
							.value -
						(data.series[parentCount][seriesValueLength - (i + 2)]
							.value -
							data.series[parentCount][
								seriesValueLength - (i + 1)
							].value) *
							0.3;
				}

				//Increment parent count so we can go to next item (ie status or resource)
				parentCount++;
			}
			result.chartData = data;
			result.breakoutData = breakoutItems;

			//Expose analytics data to app actions
			exposeAnalytics('breakoutItems', exposedItems);

			return result;
		}

		function exposeAnalytics(key, value) {
			var analytics = seedcodeCalendar.get('analytics');
			analytics = analytics && analytics !== null ? analytics : {};
			analytics[key] = value;
			seedcodeCalendar.init('analytics', analytics);
		}

		function createTicks(highValue) {
			var ticks = [];
			for (var i = 0; i <= highValue; i++) {
				ticks.push(i);
			}
			return ticks;
		}

		$scope.showSettings = showSettings;

		$scope.close = close;

		$scope.changeTab = function (name) {
			$scope.measure.settingsTab = name;
		};

		function showSettings(show) {
			$scope.settingsShown = show;
			$rootScope.$broadcast('calendarInfoSettings', show);
			$scope.calendar.config.showMeasureSettings = show;
			dataStore.saveState('showMeasureSettings', show);
		}

		function close(e) {
			//Stop propagation so we don't close popovers behind the modal when clicking close
			if (e) {
				e.stopPropagation();
			}
			$scope.popover.config.show = false;
		}

		function updateMeasureItem(measureItem) {
			var thresholdTempValue;
			if (
				measureItem === 'measureField' &&
				!measureFieldAvailable($scope.calendar.config.measureField)
			) {
				return;
			} else if (measureItem === 'measureThreshold') {
				// Make sure to strip non numeric characters - We only want Integers and Floats
				if ($scope.calendar.config.measureThreshold) {
					// Create a temp value that only contains numbers and periods split at the period for further evaluation
					thresholdTempValue = $scope.calendar.config.measureThreshold
						.replace(/[^\d.]/g, '')
						.split('.');

					// Remove additional periods (only allow the first one)
					if (thresholdTempValue.length === 1) {
						$scope.calendar.config.measureThreshold =
							thresholdTempValue[0];
					} else {
						$scope.calendar.config.measureThreshold =
							thresholdTempValue[0] +
							'.' +
							thresholdTempValue.slice(1).join('');
					}
				}
			}
			manageConfig.applyLocalSetting(measureItem, true, true);
			$rootScope.$broadcast('eventsRendered');
		}

		function measureFieldAvailable(measureField) {
			for (var i = 0; i < $scope.availableMeasureFields.length; i++) {
				if ($scope.availableMeasureFields[i].value === measureField) {
					return true;
				}
			}
			return false;
		}

		function availableMeasureFields() {
			var fields = [{title: '', value: ''}];
			var fieldObject;
			var config = seedcodeCalendar.get('config');
			var schedules = seedcodeCalendar.get('schedules');
			var shareSources = seedcodeCalendar.get('shareSources');
			var shareSource;

			if (!schedules) {
				return [];
			} else if (
				config.isShare &&
				schedules.length === 1 &&
				schedules[0].share
			) {
				schedules = Object.values(schedules[0].share.schedules);
			}

			//Add title field
			fieldObject = {
				title: 'Title',
				value: 'titleEdit',
			};
			fields.push(fieldObject);

			for (var i = 0; i < schedules.length; i++) {
				if (!schedules[i].status.selected) {
					continue;
				}

				//Add builtin fields (description field)
				if (
					config.isShare ||
					((!schedules[i].unusedMap ||
						!schedules[i].unusedMap.description) &&
						schedules[i].fieldMap.description)
				) {
					fieldObject = {
						title: 'Description',
						value: 'description',
					};
					fields.push(fieldObject);
				}

				//Add custom fields
				// shareSource = shareSources ? shareSources[schedules[i].id] : null;
				if (shareSources) {
					shareSource = shareSources[schedules[i].id]
						? shareSources[schedules[i].id]
						: shareSources[schedules[i].sourceID];
				} else {
					shareSource = null;
				}

				if (config.isShare && shareSource) {
					addCustomFieldNames(
						utilities.getShareScheduleProperties(
							shareSource,
							schedules[i].id,
							'customFields'
						),
						fields
					);
				} else {
					addCustomFieldNames(schedules[i].customFields, fields);
				}
			}

			function addCustomFieldNames(customFieldData, fields) {
				if (customFieldData) {
					for (var property in customFieldData) {
						fieldObject = {
							title: customFieldData[property].name,
							value: customFieldData[property].name,
						};
						fields.push(fieldObject);
					}
				}
			}

			return utilities.removeObjectArrayDupes(
				fields,
				'value',
				null,
				true
			);
		}
	}
})();
